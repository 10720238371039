
@import url('https://fonts.googleapis.com/css?family=Monoton');

/***** [HL ORGANIZATION] *******/

body 
{
  min-height: 100vh;
  background-image: url(./images/gradient_bg.svg); 
  background-size: cover;
}

.page-wrapper{
	height: 100vh;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;

}

.container{
	transform: translateY(-150px);
}


@media only screen and (max-width: 850px){
	.container{
	  transform: scale(0.55) translateY(-150px);
	}
} 
/***** [TEXT] *******/

.text-containers{
	width: 500px;
	height: 300px;
	display: flex; 
	flex-direction: column;
	align-items: center;
	transform: translateY(400px) translateX(235px);
	animation-name: loadin; 
	animation-duration: 2s;
}

@keyframes loadin {
	0%{
	  opacity: 0%;
	}
	100%{
	  opacity: 100%;
	}
  }


.wrap-jumbo{
	transform: translateY(20px);
}

.wrap-join-list-form{
	transform: translateY(170px);
	font-family: 'arial';
}


.subtitle{
	transform: translateY(140px);
	font-size: 22px;
	letter-spacing: -3px;
	font-weight: bold;
	font-family: 'arial';
}

/***** [SVG] *******/

.st0{fill:none;stroke:url(#SVGID_1_);stroke-width:0.8;stroke-miterlimit:10;}
.st1{opacity:0.74;}
.st2{fill:none;stroke:url(#SVGID_00000047779330716252005200000004781607761635915913_);stroke-width:0.8;stroke-miterlimit:10;}
.st3{fill:none;stroke:url(#SVGID_00000174589485199567406600000001542510229541838000_);stroke-width:0.8;stroke-miterlimit:10;}
.st4{fill:none;stroke:url(#SVGID_00000056397777296101390230000012378604992682195388_);stroke-width:0.8;stroke-miterlimit:10;}
.st5{fill:none;stroke:url(#SVGID_00000043451237567246471580000001433435829086914717_);stroke-width:0.8;stroke-miterlimit:10;}
.st6{fill:none;stroke:url(#SVGID_00000181781611524235028990000000388223797909668527_);stroke-width:0.8;stroke-miterlimit:10;}
.st7{fill:none;stroke:url(#SVGID_00000163051484716797732480000001118390991217397379_);stroke-width:0.8;stroke-miterlimit:10;}
.st8{fill:none;stroke:url(#SVGID_00000107557052554552037700000015910561210501306036_);stroke-width:0.8;stroke-miterlimit:10;}
.st9{fill:none;stroke:url(#SVGID_00000039823773942105858670000007324554976802574739_);stroke-width:0.8;stroke-miterlimit:10;}
.st10{fill:none;stroke:url(#SVGID_00000047019605555683237260000018218994337909117832_);stroke-width:0.8;stroke-miterlimit:10;}
.st11{fill:none;stroke:url(#SVGID_00000081640722842067046850000015017494008923327155_);stroke-width:0.8;stroke-miterlimit:10;}
.st12{fill:none;stroke:url(#SVGID_00000057834994359680749170000012225890366360305587_);stroke-width:0.8;stroke-miterlimit:10;}
.st13{fill:none;stroke:url(#SVGID_00000068662650974429297900000010540434433463167893_);stroke-width:0.8;stroke-miterlimit:10;}
.st14{fill:none;stroke:url(#SVGID_00000175308474296553302830000017309668915267842219_);stroke-width:0.8;stroke-miterlimit:10;}
.st15{display:none;fill:none;}
.st16{fill:#F4F4F6;}
.st17{opacity:0.72;}
.st18{fill:none;stroke:#F4F4F6;stroke-width:0.2;stroke-miterlimit:10;}
.st19{fill:none;stroke:url(#SVGID_00000093883231417488184660000015139785021199506862_);stroke-width:3;stroke-miterlimit:10;}
.st20{display:none;fill:#190400;stroke:url(#SVGID_00000164514818415631131470000014983267633883067291_);stroke-miterlimit:10;}
.st21{fill:#190400;stroke:url(#SVGID_00000170241536148008659810000013149196050468236418_);stroke-miterlimit:10;}
.st22{fill:#190400;stroke:url(#SVGID_00000111888343099561097140000005206336831750913922_);stroke-miterlimit:10;}
.st23{fill:url(#SVGID_00000144305803382615339690000012628096959515967397_);}
.st24{fill:#190400;stroke:url(#SVGID_00000121261860051749930650000001313708351597939340_);stroke-miterlimit:10;}
.st25{fill:#190400;stroke:url(#SVGID_00000115483112912214831250000013673390597427149723_);stroke-miterlimit:10;}
.st26{fill:url(#SVGID_00000170259324597444082300000012946331198003929501_);}
.st27{fill:#190400;stroke:url(#SVGID_00000117648645610168595800000009843718260327923623_);stroke-miterlimit:10;}
.st28{fill:#190400;stroke:url(#SVGID_00000075871089071992982150000005342163798340881545_);stroke-miterlimit:10;}
.st29{fill:url(#SVGID_00000143599492531707061070000002244428189670382488_);}
.st30{fill:#190400;stroke:url(#SVGID_00000100368368416299994750000014181315696844244655_);stroke-miterlimit:10;}
.st31{fill:url(#SVGID_00000046310756231361693680000002623712598408342149_);}
.st32{fill:#190400;stroke:url(#SVGID_00000081649824056262459570000007630060918143377585_);stroke-miterlimit:10;}
.st33{fill:url(#SVGID_00000001657035794898555000000016273382833601392310_);}
.st34{fill:#190400;stroke:url(#SVGID_00000040532462543214478860000004805997595789111425_);stroke-miterlimit:10;}
.st35{fill:url(#SVGID_00000145031241576789742200000005998339096955012234_);}
.st36{fill:#190400;stroke:url(#SVGID_00000163066127205083694800000011198280217656264345_);stroke-miterlimit:10;}
.st37{fill:url(#SVGID_00000122695611588076606730000012728439199169413563_);}
.st38{fill:url(#SVGID_00000078743669076217996600000000086391870052991900_);}
.st39{fill:none;stroke:#C6B3F4;stroke-width:0.2;stroke-miterlimit:10;}
.st40{fill:url(#SVGID_00000098904635271746086850000008861180790026726319_);}
.st41{fill:url(#SVGID_00000000909727257221210970000015047001681762050188_);}
.st42{fill:url(#SVGID_00000058565618053959925400000000800720714749740177_);}
.st43{fill:url(#SVGID_00000006675996287480750410000006350599810191447942_);}
.st44{fill:url(#SVGID_00000109022035026813083890000014586612237360872076_);}
.st45{fill:url(#SVGID_00000067204143121550856440000015208007889415241128_);}
.st46{fill:url(#SVGID_00000121261196343570418220000016382382885640781222_);}
.st47{fill:url(#SVGID_00000075150302860754550950000013111374713645540010_);}
.st48{fill:url(#SVGID_00000161595773114322061780000007917003016657496239_);}
.st49{fill:url(#SVGID_00000057140000085909179860000007940522235227355803_);}
.st50{fill:url(#SVGID_00000079444201702944490790000016974764247628495001_);}
.st51{fill:url(#SVGID_00000093155009420687712500000001260056924313472134_);}
.st52{fill:url(#SVGID_00000150071068482879695760000001117625951692987543_);}
.st53{fill:url(#SVGID_00000081626517829654280410000001965514645469342596_);}
.st54{fill:url(#SVGID_00000137848163414021530430000015619347075384998035_);}
.st55{fill:url(#SVGID_00000106857447196137431170000012313280360322192512_);}
.st56{fill:url(#SVGID_00000064342012837631159270000005610021364186881959_);}
.st57{fill:url(#SVGID_00000108280536386427641360000001371724474179590803_);}
.st58{fill:url(#SVGID_00000139294842182904913160000007190027270797140098_);}
.st59{fill:url(#SVGID_00000133527141234920817130000008984884815082097835_);}
.st60{fill:url(#SVGID_00000162347461862736263560000008932494416929643687_);}
.st61{fill:url(#SVGID_00000060011653059582157410000001897272367097053851_);}
.st62{fill:url(#SVGID_00000155832266830811020770000015991614947805870254_);}
.st63{fill:url(#SVGID_00000052087250374085390110000011195023552809848760_);}
.st64{fill:url(#SVGID_00000146483127723153042130000018323292862659987625_);}
.st65{fill:url(#SVGID_00000123440113007870351750000003439530393207912624_);}
.st66{fill:url(#SVGID_00000111183314545159945610000011200629544935662486_);}
.st67{fill:url(#SVGID_00000168821055030140350960000001114298622740149915_);}
.st68{fill:url(#SVGID_00000096038134686843369300000017141872941416939188_);}
.st69{fill:url(#SVGID_00000015330359739380736860000016817691657277462970_);}
.st70{fill:url(#SVGID_00000111887480222564186360000000690826034287954333_);}
.st71{fill:none;stroke:url(#SVGID_00000139979468063282477230000004654279606026785452_);stroke-width:0.2;stroke-miterlimit:10;}
.st72{fill:url(#SVGID_00000178206489075487975680000003081692947045518211_);}
.st73{fill:url(#SVGID_00000013900587245639050860000016366694463030857629_);}
.st74{fill:none;stroke:url(#SVGID_00000095341190250175772030000010383501784649875102_);stroke-width:0.2;stroke-miterlimit:10;}
.st75{fill:url(#SVGID_00000174586932883840551660000013822208096999423876_);}
.st76{fill:url(#SVGID_00000011740593740947976930000004834497147028007325_);}
.st77{fill:none;stroke:url(#SVGID_00000054985100546799671360000014405792335688939400_);stroke-width:0.2;stroke-miterlimit:10;}
.st78{fill:url(#SVGID_00000145043225691698629700000005772906917635242425_);}
.st79{fill:url(#SVGID_00000111882135094737314170000002770620457575799481_);}
.st80{fill:none;stroke:url(#SVGID_00000080202413214105871540000015514483027675232934_);stroke-width:0.2;stroke-miterlimit:10;}
.st81{fill:url(#SVGID_00000003097445057293518000000002079198852095994037_);}
.st82{fill:url(#SVGID_00000088123327262209783120000017268866102818188437_);}
.st83{fill:none;stroke:url(#SVGID_00000013875842877922623670000010567718319898394498_);stroke-width:0.2;stroke-miterlimit:10;}
.st84{fill:url(#SVGID_00000106132087372093268190000008794144794819869069_);}
.st85{fill:url(#SVGID_00000176013975333514975220000017075621737648365992_);}
.st86{fill:none;stroke:url(#SVGID_00000035493153491869986040000014250852201983449225_);stroke-width:0.2;stroke-miterlimit:10;}
.st87{fill:url(#SVGID_00000013169992322967131870000000784722742443781278_);}
.st88{fill:url(#SVGID_00000100365788526515864760000013697346067407729802_);}
.st89{fill:none;stroke:url(#SVGID_00000113323656026368539280000016774739954402341251_);stroke-width:0.2;stroke-miterlimit:10;}
.st90{fill:url(#SVGID_00000091015389253484834270000018350879846141542845_);}
.st91{fill:url(#SVGID_00000030488077060225002360000005698274062161832588_);}
.st92{fill:none;stroke:url(#SVGID_00000024001495247534378930000012555103719640926612_);stroke-width:0.2;stroke-miterlimit:10;}
.st93{fill:url(#SVGID_00000142166282555496431930000017641053572564519810_);}
.st94{fill:url(#SVGID_00000063627618313259435930000002664949612045657019_);}
.st95{fill:none;stroke:url(#SVGID_00000165223745983372349280000001178646784450811064_);stroke-width:0.2;stroke-miterlimit:10;}
.st96{fill:url(#SVGID_00000179607248810145956420000003127868632598862227_);}
.st97{fill:url(#SVGID_00000046318014460130235370000001907751416869234322_);}
.st98{fill:none;stroke:url(#SVGID_00000104686755290674204460000001654131827217223325_);stroke-width:0.2;stroke-miterlimit:10;}
.st99{fill:url(#SVGID_00000183940064930355495200000000727711901255595953_);}
.st100{fill:url(#SVGID_00000148654301655706449290000005048495286900645251_);}
.st101{fill:none;stroke:url(#SVGID_00000009562654336589025840000012686624810227831209_);stroke-width:0.2;stroke-miterlimit:10;}
.st102{fill:url(#SVGID_00000123405270545649360490000013618267184242184583_);}
.st103{fill:url(#SVGID_00000074401423396178961720000011869330692383559059_);}
.st104{fill:none;stroke:url(#SVGID_00000051373603508639185680000015874672272709621666_);stroke-width:0.2;stroke-miterlimit:10;}
.st105{fill:url(#SVGID_00000109029017506719826180000014102340809651652768_);}
.st106{fill:url(#SVGID_00000162333501194787838970000012122520161045509567_);}
.st107{fill:none;stroke:url(#SVGID_00000005250897579354828990000000083175851904820664_);stroke-width:0.2;stroke-miterlimit:10;}
.st108{fill:url(#SVGID_00000032624441638281554670000009500275930082466197_);}
.st109{fill:url(#SVGID_00000024687320464469612110000005684426378237780616_);}
.st110{fill:none;stroke:url(#SVGID_00000129925899538164521300000016850930465915311764_);stroke-width:0.2;stroke-miterlimit:10;}
.st111{fill:url(#SVGID_00000134208139163703944730000015726618421932706700_);}
.st112{fill:url(#SVGID_00000035500248658939047180000005925861580465283221_);}
.st113{fill:none;stroke:url(#SVGID_00000018940876908033871160000012903639985911585425_);stroke-width:0.2;stroke-miterlimit:10;}
.st114{fill:url(#SVGID_00000161614622112795419670000013921673676946823303_);}
.st115{fill:url(#SVGID_00000019654901801694164910000017266641597491342479_);}
.st116{fill:none;stroke:url(#SVGID_00000160180888448913980340000001024126232198123654_);stroke-width:0.2;stroke-miterlimit:10;}
.st117{fill:url(#SVGID_00000044891829709143506050000011671881227327299773_);}
.st118{fill:url(#SVGID_00000098198181328812817860000009237301979411573421_);}
.st119{fill:none;stroke:url(#SVGID_00000003816711949416962720000000454574298321057162_);stroke-width:0.2;stroke-miterlimit:10;}
.st120{fill:url(#SVGID_00000125565171754573341210000009561688056786641546_);}
.st121{fill:url(#SVGID_00000076565190996392629390000012608170850915153061_);}
.st122{fill:none;stroke:url(#SVGID_00000149366424546377477130000002411845463143076506_);stroke-width:0.2;stroke-miterlimit:10;}
.st123{fill:url(#SVGID_00000139265630063997634930000017057960744082022040_);}
.st124{fill:url(#SVGID_00000046327782321622780240000008626019799731513503_);}
.st125{fill:none;stroke:url(#SVGID_00000121993336639686297940000014121824267563267518_);stroke-width:0.2;stroke-miterlimit:10;}
.st126{fill:url(#SVGID_00000150097150366223965580000001208108860353555125_);}
.st127{fill:url(#SVGID_00000140723070821689189950000015731998443433872313_);}
.st128{fill:none;stroke:url(#SVGID_00000034089394602951977730000000515786117003852166_);stroke-width:0.2;stroke-miterlimit:10;}
.st129{fill:url(#SVGID_00000093873256306451334870000006676835250992109216_);}
.st130{fill:url(#SVGID_00000163051226096845477750000016457526299905589938_);}
.st131{fill:none;stroke:url(#SVGID_00000150074346951336037990000016679633532417233338_);stroke-width:0.2;stroke-miterlimit:10;}
.st132{fill:url(#SVGID_00000119820806978160468740000004658797037659387027_);}
.st133{fill:url(#SVGID_00000045586809837924296100000018209119979024553370_);}
.st134{fill:none;stroke:url(#SVGID_00000139267315373769998620000012161370545592997298_);stroke-width:0.2;stroke-miterlimit:10;}
.st135{fill:url(#SVGID_00000054963029058512399440000002904626172165373108_);}
.st136{fill:url(#SVGID_00000145757641908694195400000006331212640242987150_);}
.st137{fill:none;stroke:url(#SVGID_00000160889113771347227110000013005638051960993196_);stroke-width:0.2;stroke-miterlimit:10;}
.st138{fill:url(#SVGID_00000162310740012905356120000009634861711038935682_);}
.st139{fill:url(#SVGID_00000017507023914799341360000016147147544699796639_);}
.st140{fill:none;stroke:url(#SVGID_00000095339000828604311020000005736947996181565117_);stroke-width:0.2;stroke-miterlimit:10;}
.st141{fill:url(#SVGID_00000171686120168215407840000012660946645450447039_);}
.st142{fill:url(#SVGID_00000083795723476789833390000005877919428029388210_);}
.st143{fill:none;stroke:url(#SVGID_00000115514824767544885800000004131768746360324031_);stroke-width:0.2;stroke-miterlimit:10;}
.st144{fill:url(#SVGID_00000098186076388629129390000014040658676917868713_);}
.st145{fill:url(#SVGID_00000182510895140883894350000016670265186265753013_);}
.st146{fill:none;stroke:url(#SVGID_00000030478061840106955210000011140627679109134214_);stroke-width:0.2;stroke-miterlimit:10;}
.st147{fill:url(#SVGID_00000097480443781240450880000009709719307942582440_);}
.st148{fill:url(#SVGID_00000135650292166500328980000003727061197160798855_);}
.st149{fill:none;stroke:url(#SVGID_00000113343891540659201690000018167465074625834644_);stroke-width:0.2;stroke-miterlimit:10;}
.st150{fill:url(#SVGID_00000060015523973420425440000003923588336834053558_);}
.st151{fill:url(#SVGID_00000116195917720306099770000008351868922695261371_);}
.st152{fill:none;stroke:url(#SVGID_00000000929608443405301710000005959162735402237372_);stroke-width:0.2;stroke-miterlimit:10;}
.st153{fill:url(#SVGID_00000091695396241117735070000002291945036622479020_);}
.st154{fill:url(#SVGID_00000172420089420642805430000015824797706702368897_);}
.st155{fill:none;stroke:url(#SVGID_00000073690478555057687530000015685744141417420957_);stroke-width:0.2;stroke-miterlimit:10;}
.st156{fill:url(#SVGID_00000043440055074596105290000011215047146337947787_);}
.st157{fill:url(#SVGID_00000005983920505282486510000013426766897802412167_);}
.st158{fill:none;stroke:url(#SVGID_00000155112524278228300600000013561795196290773947_);stroke-width:0.2;stroke-miterlimit:10;}
.st159{fill:url(#SVGID_00000019646163400430466770000014933028935774770574_);}
.st160{fill:url(#SVGID_00000179645102284928895200000011595052054764089992_);}
.st161{fill:none;stroke:url(#SVGID_00000088126141533130136870000006052028191596000916_);stroke-width:0.2;stroke-miterlimit:10;}
.st162{fill:url(#SVGID_00000142147888318034545890000007914352735963770522_);}
.st163{fill:url(#SVGID_00000009581392565178672280000018092690356906965122_);}
.st164{fill:none;stroke:url(#SVGID_00000065761443689815990780000000692437059851987585_);stroke-width:0.2;stroke-miterlimit:10;}
.st165{fill:url(#SVGID_00000091713309026543770170000010248184336226854542_);}
.st166{fill:url(#SVGID_00000018198020885223882200000003177197033719917222_);}
.st167{fill:none;stroke:url(#SVGID_00000170989706544540649780000001738449037166667955_);stroke-width:0.2;stroke-miterlimit:10;}
.st168{fill:url(#SVGID_00000013158116739035073430000009485596419904039340_);}
.st169{fill:url(#SVGID_00000090276949276717297370000015870727234554250913_);}
.st170{fill:none;stroke:url(#SVGID_00000085228219571319467710000013184849870190350498_);stroke-width:0.2;stroke-miterlimit:10;}
.st171{fill:url(#SVGID_00000039847216279594088960000007869111918961420694_);}
.st172{fill:url(#SVGID_00000163036094495746672880000017454211456291123102_);}
.st173{fill:none;stroke:url(#SVGID_00000080166935909083775180000012693753517337561769_);stroke-width:0.2;stroke-miterlimit:10;}
.st174{fill:url(#SVGID_00000178190610794877774470000012896299456756207488_);}
.st175{fill:url(#SVGID_00000080182540680612310630000009521852397390506641_);}
.st176{fill:none;stroke:url(#SVGID_00000065758370383057860440000000251997596655488955_);stroke-width:0.2;stroke-miterlimit:10;}
.st177{fill:url(#SVGID_00000078009813688307540110000001393687807247782539_);}
.st178{fill:url(#SVGID_00000087378768357952407290000014804870305277194643_);}
.st179{fill:none;stroke:url(#SVGID_00000143605417429004478330000009605943944892986258_);stroke-width:0.2;stroke-miterlimit:10;}
.st180{fill:url(#SVGID_00000012443354747994421880000013115546079815034008_);}
.st181{fill:url(#SVGID_00000003800408231212679440000003657287228131498390_);}
.st182{fill:none;stroke:url(#SVGID_00000024711302135722649430000009585840832393529999_);stroke-width:0.2;stroke-miterlimit:10;}
.st183{fill:url(#SVGID_00000045594745400218203450000007405077380062404002_);}
.st184{fill:url(#SVGID_00000129905880755385620040000016315485783264227260_);}
.st185{fill:none;stroke:url(#SVGID_00000015356218819401377070000015095712599385480375_);stroke-width:0.2;stroke-miterlimit:10;}
.st186{fill:url(#SVGID_00000057835506515517219140000001825977590160713612_);}
.st187{fill:url(#SVGID_00000158714683466108422510000004522429951656262791_);}
.st188{fill:none;stroke:url(#SVGID_00000090270789078013053730000005235802525550551224_);stroke-width:0.2;stroke-miterlimit:10;}
.st189{fill:url(#SVGID_00000126285777844137772170000000496348238261971585_);}
.st190{fill:url(#SVGID_00000137852523727300926850000005513055450594144699_);}
.st191{fill:none;stroke:url(#SVGID_00000108298608345681071730000006067933157034291621_);stroke-width:0.2;stroke-miterlimit:10;}
.st192{fill:url(#SVGID_00000140720181511671742960000018429336337423086980_);}
.st193{fill:url(#SVGID_00000013904908765302576390000017950728167626100647_);}
.st194{fill:none;stroke:url(#SVGID_00000101070311433725789300000004042701107682855067_);stroke-width:0.2;stroke-miterlimit:10;}
.st195{fill:url(#SVGID_00000054256688875463856400000017739281343613472441_);}
.st196{fill:url(#SVGID_00000032644401440460717310000012467681294692155541_);}
.st197{fill:none;stroke:url(#SVGID_00000152261058419795913560000010391160827818054529_);stroke-width:0.2;stroke-miterlimit:10;}
.st198{fill:url(#SVGID_00000166673436344219020920000010859254976259912353_);}
.st199{fill:url(#SVGID_00000093858642971061545910000016576478804193656973_);}
.st200{fill:none;stroke:url(#SVGID_00000064320383535197213410000012646426774263887270_);stroke-width:0.2;stroke-miterlimit:10;}
.st201{fill:url(#SVGID_00000147178421841005109500000000635172872879039161_);}
.st202{fill:url(#SVGID_00000173159557201177088360000001021538649525201827_);}
.st203{fill:none;stroke:url(#SVGID_00000023248420611095989130000001085112498141511320_);stroke-width:0.2;stroke-miterlimit:10;}
.st204{fill:url(#SVGID_00000177462171009733292160000006981552513887379109_);}
.st205{fill:url(#SVGID_00000024695397514308300340000003032548278782840997_);}
.st206{fill:none;stroke:url(#SVGID_00000152945608632321038150000012752200512751084732_);stroke-width:0.2;stroke-miterlimit:10;}
.st207{fill:url(#SVGID_00000060744663276887209630000004543857508570479017_);}
.st208{fill:url(#SVGID_00000178171631884085043620000015413114943457246106_);}
.st209{fill:none;stroke:url(#SVGID_00000018222371199420376680000012313843006420080575_);stroke-width:0.2;stroke-miterlimit:10;}
.st210{fill:url(#SVGID_00000115504409811963383860000016290655894127818672_);}
.st211{fill:url(#SVGID_00000117671481528143578040000006683640177448613007_);}
.st212{fill:none;stroke:url(#SVGID_00000009553651883748388870000008565139668575883650_);stroke-width:0.2;stroke-miterlimit:10;}
.st213{fill:url(#SVGID_00000100341278616057537920000010260474443202551211_);}
.st214{fill:url(#SVGID_00000040564838186353087700000014573213267364103080_);}
.st215{fill:none;stroke:url(#SVGID_00000067937645463414535240000009567420272292695971_);stroke-width:0.2;stroke-miterlimit:10;}
.st216{fill:url(#SVGID_00000050645169272437163460000008615897974196028598_);}
.st217{fill:url(#SVGID_00000130614043943069060600000006165200492142981821_);}
.st218{fill:none;stroke:url(#SVGID_00000143589638658834051320000017345206907034961811_);stroke-width:0.2;stroke-miterlimit:10;}
.st219{fill:url(#SVGID_00000019665398364003824490000012169625769157175999_);}
.st220{fill:url(#SVGID_00000142134929044288778010000005234044636850134406_);}
.st221{fill:none;stroke:url(#SVGID_00000039124703301729677470000016940790293261716151_);stroke-width:0.2;stroke-miterlimit:10;}
.st222{fill:url(#SVGID_00000178889636736727359160000001923051264132375436_);}
.st223{fill:url(#SVGID_00000034790043421685394220000015035521264195623862_);}
.st224{fill:none;stroke:url(#SVGID_00000048473277795646038870000010673127791725032867_);stroke-width:0.2;stroke-miterlimit:10;}
.st225{fill:url(#SVGID_00000137115176418570909390000017906589988718462630_);}
.st226{fill:url(#SVGID_00000019648935464103622810000011727240606798376595_);}
.st227{fill:none;stroke:url(#SVGID_00000125587345451618650750000012849355865364823198_);stroke-width:0.2;stroke-miterlimit:10;}
.st228{fill:url(#SVGID_00000039822831383691796720000013012227559994876847_);}
.st229{fill:url(#SVGID_00000111883608768682279340000005517226529331729281_);}
.st230{fill:none;stroke:url(#SVGID_00000016046694014327545170000009184592393285126555_);stroke-width:0.2;stroke-miterlimit:10;}
.st231{fill:url(#SVGID_00000178897822047937577690000003794661309397471399_);}
.st232{fill:url(#SVGID_00000055702564693278211770000009153235549586960050_);}
.st233{fill:none;stroke:url(#SVGID_00000139982828647693618850000008037672106078150054_);stroke-width:0.2;stroke-miterlimit:10;}
.st234{fill:url(#SVGID_00000176756841922524247900000007668755116936172473_);}
.st235{fill:url(#SVGID_00000010312513139460942880000002568787841076164536_);}
.st236{fill:none;stroke:url(#SVGID_00000176756874771410359180000007655386640727948477_);stroke-width:0.2;stroke-miterlimit:10;}
